<template>
  <div class="container">
    <div style="margin-bottom: 3.4375rem;">
      <div class="flex flex-direction justify-center info-base">
        <div class="flex-sub flex flex-direction">
          <div class="text-bold padding-bottom" style="font-size: 22px;">
            {{ data.title }}
          </div>
          <div
            class="flex align-center"
            style="padding-top: 0.5rem; font-size: 15px; color: #666;"
          >
            {{ publishTime(data.releaseTime) }}
          </div>
        </div>
      </div>

      <div class="activity-details">
        <div class="ql-editor" v-html="data.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/utils/request";

export default {
  name: "VCFrontierDetail",
  components: {},
  data() {
    return {
      data: {
        browse: 0,
        content: "",
        id: 0,
        picture: "",
        publishTime: "",
        title: ""
      }
    };
  },
  computed: {
    // 计算最新的开始时间
    publishTime() {
      return function(s) {
        if (s) {
          let res = s.split(" ");
          let params = res[0].split("-");
          let year = params[0] + "年";
          let month = params[1] + "月";
          let day = params[2] + "日";
          return year + month + day;
        } else {
          return "2020年10月1日";
        }
      };
    }
  },
  beforeMount() {
    console.log(this.$route.query);
    this.data.id = this.$route.query.id;
  },
  mounted() {
    this.request();
  },
  methods: {
    // 请求
    request() {
      let url =
        "https://xinke.thinkervc.cn/api/applet/activityCms/getActivityCms";
      url += "?id=" + this.data.id;
      request(url, {}, "post").then(res => {
        if (res.code === 200) {
          this.data = res.data;
        }
      });
    }
  }
};
</script>

<style>
page {
  background-color: #fff;
  overflow-x: hidden;
}
</style>
<style scoped lang="scss">
.container {
  background-color: #fff;

  .info-base {
    padding: 5%;
    padding-bottom: 0;
  }

  .activity-details {
    padding: 5%;
    padding-bottom: 3%;
  }
}
</style>
